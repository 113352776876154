(function ($, generic) {
  /**
    * Event listener to initalize the waitlist button when a product is sold out or coming soon.
    * Event call is coming from js/site/product_ui.js when a product is initalized or a sku is selected.
    * Passes product data to the init method of productWaitlistV1.
  */
  $(document).on('product.waitlist.init', function (event, data) {
    Drupal.behaviors.productWaitlistV1.init(data);
  });

  /**
    * Event listener to reset or hide the waitlist button when a product is available.
    * Passes product data to the reset method of productWaitlistV1.
  */
  $(document).on('product.waitlist.reset', function (event, data) {
    Drupal.behaviors.productWaitlistV1.reset(data);
  });

  Drupal.behaviors.productWaitlistV1 = {

    /**
      * Init method that handles the functionality for the notify button on a product.
      * Before initialization, does an additional check for sold out products to make sure
      *   life of product basic reorder is set.
      * If everything checks out, the waitlist button is shown and the overlay event trigger is added.
      * The show method is used to display the notify button and hide the add to bag button.
      * The product SKU_BASE_ID is also added to the notify button.
    */
    init: function (data) {
      if (!data) {
        return null;
      }

      // Only show overlay for sold out products when LIFE_OF_PRODUCT is basic reorder.
      if (data.INVENTORY_STATUS === this.statusIds.inventory.soldOut
           && data.LIFE_OF_PRODUCT !== this.statusIds.lifeOfProduct.basicReorder) {
        return null;
      }
      var that = this;
      var $container = $(this.classes.container).filter('[data-product-id=' + data.PRODUCT_ID + ']');
      var $waitlistBtns = $(this.classes.waitlistBtn, $container);
      var skuStatus = data.SKU_STATUS;

      $waitlistBtns.each(function (index, btn) {
        if ($(btn).hasClass(that.classes.enabled) && skuStatus !== 6) {
          return null;
        }

        that.show($container, btn, skuStatus);

        $(btn).attr('data-sku-base-id', data.SKU_BASE_ID);

        $(btn).on('click', function (e) {
          e.preventDefault();
          $(document).trigger('product.waitlist.overlay', data);
        });
      });
    },

    /**
      * Reset method handles hidding the notify button and re-displaying add to bag button if it was hidden.
      * Primarily used for multi-sku products.
      * Uses the hide method to handle the re-displaying of add to bag and hiding notify me.
      * Won't call the hide method unless notify button is displayed.
    */
    reset: function (data) {
      if (!data) {
        return null;
      }
      var $container = $(this.classes.container).filter('[data-product-id=' + data.PRODUCT_ID + ']');
      var $waitlistBtnContainer = $(this.classes.waitlistBtn, $container);
      var $cartBtnContainer = $(this.classes.cartBtn, $container);

      $cartBtnContainer.removeClass('button--disabled').removeAttr('disabled');
      if (!$waitlistBtnContainer.hasClass(this.classes.enabled)) {
        return null;
      }
      this.hide($container, $waitlistBtnContainer);
    },

    /**
      * Method to handle showing and enabling of the notify button and the hidding of add to bag.
    */
    show: function ($container, btn, skuStatus) {
      var $waitlistBtnContainer = $(this.classes.waitlistBtn, $container);
      var $cartBtnContainer = $(this.classes.cartBtn, $container);

      $cartBtnContainer.addClass(this.classes.hidden);
      if (skuStatus == null || skuStatus !== 6) {
        $(btn).removeClass(this.classes.hidden);
        $(btn).addClass(this.classes.active);
        $(btn).addClass(this.classes.enabled);
      } else {
        $(btn).hide();
      }
    },

    /**
      * Method to handle hiding and disabling of the notify button and showing of add to bag.
    */
    hide: function ($container, $waitlistBtnContainer) {
      var $cartBtnContainer = $(this.classes.cartBtn, $container);

      $cartBtnContainer.removeClass(this.classes.hidden);
      $waitlistBtnContainer.addClass(this.classes.hidden);
      $waitlistBtnContainer.removeClass(this.classes.active);
      $waitlistBtnContainer.removeClass(this.classes.enabled);
    },

    /**
      * Method to declare DOM classes used by waitlist.
    */
    classes: {
      waitlistBtn: '.js-add-to-waitlist',
      cartBtn: '.js-add-to-cart, .js-add-to-bag',
      invStatus: '.js-inv-status-list',
      container: '.js-product',
      enabled: 'js-waitlist-enabled',
      hidden: 'hidden',
      active: 'active'
    },

    /**
      * Status ids used by the waitlist; adjust accordingly.
    */
    statusIds: {
      inventory: {
        comingSoon: 3,
        soldOut: 7
      },
      lifeOfProduct: {
        basicReorder: 2
      }
    }

  };
})(jQuery);
